import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { query } from 'api/prismic'
import { GetStaticProps } from 'next'
import { useRouter } from 'next/router'
import { layoutQuery } from 'prismic/fragments/layoutQuery'

import { NotFound404 } from 'components/NotFound404/NotFound404'
import { Section } from 'components/section/Section'
import { Meta } from 'containers/meta/Meta'

import { localeToPrismicLocale } from 'utils/i18n'
import { Maybe, Navigation } from 'prismic-types'
import {
  singleNavigationQuery,
  topNavigationQuery,
} from 'prismic/fragments/navigationQuery'

type PageProps = {
  preview?: boolean
  navigation: (Maybe<Navigation> | undefined)[]
}

const Custom404 = ({ preview }: PageProps) => {
  const { asPath } = useRouter()
  const { formatMessage } = useIntl()
  useEffect(() => {
    if (preview) {
      document.location = '/api/exit-preview?redirect=' + asPath
    }
  })

  return (
    <Section container>
      <Meta
        title={formatMessage({
          id: 'metaTitle_404',
          defaultMessage: 'Orkusalan – Síða fannst ekki',
        })}
      />
      <NotFound404
        title={formatMessage({
          id: 'title_404',
          defaultMessage: 'Úbbs, hér er bilað stuð!',
        })}
        text={formatMessage({
          id: 'text_404',
          defaultMessage:
            'Við fundum ekki síðuna sem þú baðst um. Kannski var hún aldrei til eða mögulega týndum við henni.',
        })}
        button={formatMessage({
          id: 'button_404',
          defaultMessage: 'Til baka á forsíðu',
        })}
      />
    </Section>
  )
}

export const getStaticProps: GetStaticProps = async ({
  preview = false,
  locale,
}) => {
  const lang = localeToPrismicLocale(locale)

  // GraphQL hack for deep navigation
  const topNavData = await query(topNavigationQuery, { variables: { lang } })
  const topNav =
    topNavData?.allNavigations.edges
      ?.filter((edge) => edge && edge.node)
      .map((edge) => {
        return edge?.node._meta.uid
      }) ?? []

  const megamenuItems = async () => {
    return await Promise.all(
      topNav.map((uid) =>
        query(singleNavigationQuery, { variables: { lang, uid: uid! } })
      )
    )
  }

  const [navData, layoutData] = await Promise.all([
    megamenuItems(),
    query(layoutQuery, {
      variables: { lang },
      cacheKey: `layout-${lang}`,
    }),
  ])
  const layout = layoutData?.layout ?? null
  const navigation = navData.map((navDataItem) => navDataItem?.navigation)

  return {
    props: {
      preview,
      layout,
      navigation,
    },
  }
}

export default Custom404
