import { H1 } from 'components/heading/Heading'
import { Link } from 'components/link/Link'

import s from './NotFound404.module.scss'
import { GridLayout, GridLayoutItem } from 'components/grid/GridLayout'
import { ArrowLeft } from 'components/icon/Icon'

type Props = {
  title: string
  text: string
  button: string
}

export const NotFound404 = ({ title, text, button }: Props) => (
  <GridLayout className={s.container}>
    <GridLayoutItem
      columns={{ mobile: 12, tablet: 5, desktop: 6 }}
      className={s.image}
    >
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img src="/images/404.png" alt="" className={s.img} />
    </GridLayoutItem>
    <GridLayoutItem columns={{ mobile: 12, tablet: 7, desktop: 6 }}>
      <div className={s.notFound404}>
        <H1 type="h1" className={s.notFound404__title}>
          {title}
        </H1>
        <p className={s.notFound404__text}>{text}</p>
        <Link to="/" className={s.notFound404__link}>
          <ArrowLeft />
          {button}
        </Link>
      </div>
    </GridLayoutItem>
  </GridLayout>
)
